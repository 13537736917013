export default ($) => {

    function video_widget_init() {
        setTimeout(
            () => {

                const settings = window.vdrv_settings.settings;

                if (!settings) {
                    return
                }

                const widget_html = window.vdrv_settings.widget_html;

                /**
                 * Adding widget
                 * 
                 */
                document.body.append(stringToHTML(widget_html))

                let container = document.querySelector('#vdrv-widget'),
                    widget = document.querySelector('#vdrv-widget #vdrv-widget-video-wrapper'),
                    close_collapse_button = document.querySelector('#vdrv-widget #vdrv-widget-video-wrapper .vdrv-widget-close'),
                    video = document.querySelector('#vdrv-widget #vdrv-widget-video-wrapper .vdrv-video-widget'),
                    cta_button = document.querySelector('#vdrv-widget #vdrv-cta-button')


                if (!widget) {
                    return;
                }

                /**
                 * Widget on click logic
                 */
                widget.onclick = () => {
                    /**
                     * On opening/opened
                     */
                    if (!widget.classList.contains("closed") && !widget.classList.contains("active")) {
                        widget.classList.add("active");
                        video.muted = !video.muted;
                        if (cta_button) {
                            cta_button_logic()
                        }

                        widget.style.borderColor = settings.on_hover_widget_border
                    } 
                    /**
                     * on collapsing
                     */
                    else if (widget.classList.contains("active")) { 
                        if (video.paused == true) {
                            // Play the video
                            video.play();
                        } else {
                            // Pause the video
                            video.pause();
                        }

                        widget.style.borderColor = settings.default_widget_border
                    }

                };

                /**
                 * On hover
                 * @param {*} ev 
                 */
                widget.onmouseover = (ev)=>{
                    widget.style.borderColor = settings.on_hover_widget_border
                }

                /**
                 * On mouse out
                 * @param {*} ev 
                 */
                widget.onmouseout = (ev)=>{
                    /**
                     * If widget is not active
                     */
                    if(!widget.classList.contains("active")){
                        widget.style.borderColor = settings.default_widget_border
                    }
                }

                /**
                 * CTA Button logic
                 */
                function cta_button_logic() {
                    let seconds = settings.cta_time ? parseInt(settings.cta_time) * 1000 : 1000
                    setTimeout(() => { cta_button.classList.add("active") }, seconds)

                    /**
                     * If cta button is selected type link
                     */
                    if (settings.cta_type === 'link') {
                        cta_button.onclick = () => {
                            window.open(settings.cta_link.url, settings.cta_link.blank ? '_blank' : '_self')
                        };
                    } else if (settings.cta_type === 'action') {
                        cta_button.onclick = () => {
                            let element = document.querySelector(settings.cta_action.selector);
                            if (element) {
                                element.click()
                            }
                        };
                    } else if (settings.cta_type === 'scroll') {
                        cta_button.onclick = () => {
                            let element = document.getElementById(settings.cta_scroll.id);
                            if (element) {
                                element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                            }
                        };
                    }
                }

                /**
                 * Convert a template string into HTML DOM nodes
                 * @param  {String} str The template string
                 * @return {Node}       The template HTML
                 */
                function stringToHTML(str) {
                    var dom = document.createElement('div');
                    dom.innerHTML = str;
                    return dom;
                };

                close_collapse_button.onclick = () => {
                    if (widget.classList.contains("active")) {
                        widget.classList.remove("active");
                        widget.classList.add("closed");
                        if (cta_button) {
                            cta_button.classList.remove("active");
                        }

                        video.muted = !video.muted;
                        video.play();
                        setTimeout(() => {
                            widget.classList.remove("closed");
                        }, 1000)
                    } else if (!widget.classList.contains("active")) {
                        container.remove()
                    }

                }
            }, 1000);
    }

    /**
     * Load after full page ready + some seconds 
     */
    custom_on_load(video_widget_init);

    /**
     * On load custom function
     * @param {*} callback 
     */
    function custom_on_load(callback) {
        if (window.addEventListener)
            window.addEventListener("load", callback, false);
        else if (window.attachEvent)
            window.attachEvent("onload", callback);
        else window.onload = callback;
    }
}